export const AboutTabs = () => import('../../src/components/about/AboutTabs.vue' /* webpackChunkName: "components/about-tabs" */).then(c => wrapFunctional(c.default || c))
export const AboutFeaturedInList = () => import('../../src/components/about/FeaturedInList.vue' /* webpackChunkName: "components/about-featured-in-list" */).then(c => wrapFunctional(c.default || c))
export const ArtistsCoverImage = () => import('../../src/components/artists/CoverImage.vue' /* webpackChunkName: "components/artists-cover-image" */).then(c => wrapFunctional(c.default || c))
export const ChannelsChannelMediaTypeCategories = () => import('../../src/components/channels/ChannelMediaTypeCategories.vue' /* webpackChunkName: "components/channels-channel-media-type-categories" */).then(c => wrapFunctional(c.default || c))
export const ChannelsCategoriesListItem = () => import('../../src/components/channels/ChannelsCategoriesListItem.vue' /* webpackChunkName: "components/channels-categories-list-item" */).then(c => wrapFunctional(c.default || c))
export const ChannelsHero = () => import('../../src/components/channels/ChannelsHero.vue' /* webpackChunkName: "components/channels-hero" */).then(c => wrapFunctional(c.default || c))
export const ChannelsCustomizeGif = () => import('../../src/components/channels/CustomizeGif.vue' /* webpackChunkName: "components/channels-customize-gif" */).then(c => wrapFunctional(c.default || c))
export const ChannelsFreeStockBanner = () => import('../../src/components/channels/FreeStockBanner.vue' /* webpackChunkName: "components/channels-free-stock-banner" */).then(c => wrapFunctional(c.default || c))
export const ChannelsGifUseCases = () => import('../../src/components/channels/GifUseCases.vue' /* webpackChunkName: "components/channels-gif-use-cases" */).then(c => wrapFunctional(c.default || c))
export const ChannelsHowToUseMidjourney = () => import('../../src/components/channels/HowToUseMidjourney.vue' /* webpackChunkName: "components/channels-how-to-use-midjourney" */).then(c => wrapFunctional(c.default || c))
export const ChannelsLottieBenefits = () => import('../../src/components/channels/LottieBenefits.vue' /* webpackChunkName: "components/channels-lottie-benefits" */).then(c => wrapFunctional(c.default || c))
export const ChannelsLottieDemo = () => import('../../src/components/channels/LottieDemo.vue' /* webpackChunkName: "components/channels-lottie-demo" */).then(c => wrapFunctional(c.default || c))
export const ChannelsLottieDemoSwiperSlides = () => import('../../src/components/channels/LottieDemoSwiperSlides.vue' /* webpackChunkName: "components/channels-lottie-demo-swiper-slides" */).then(c => wrapFunctional(c.default || c))
export const ChannelsLottieGettingStarted = () => import('../../src/components/channels/LottieGettingStarted.vue' /* webpackChunkName: "components/channels-lottie-getting-started" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseBreadcrumbs = () => import('../../src/components/core/BaseBreadcrumbs.vue' /* webpackChunkName: "components/core-base-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseLayout = () => import('../../src/components/core/BaseLayout.vue' /* webpackChunkName: "components/core-base-layout" */).then(c => wrapFunctional(c.default || c))
export const CoreModalWrapper = () => import('../../src/components/core/ModalWrapper.vue' /* webpackChunkName: "components/core-modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const CoreTheNavBar = () => import('../../src/components/core/TheNavBar.vue' /* webpackChunkName: "components/core-the-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const BrandHero = () => import('../../src/components/brand-design-kit/BrandHero.vue' /* webpackChunkName: "components/brand-hero" */).then(c => wrapFunctional(c.default || c))
export const BrandKitLogo = () => import('../../src/components/brand-design-kit/BrandKitLogo.vue' /* webpackChunkName: "components/brand-kit-logo" */).then(c => wrapFunctional(c.default || c))
export const CorporateComparisonTable = () => import('../../src/components/corporate/ComparisonTable.vue' /* webpackChunkName: "components/corporate-comparison-table" */).then(c => wrapFunctional(c.default || c))
export const FreeBiographyCard = () => import('../../src/components/free/BiographyCard.vue' /* webpackChunkName: "components/free-biography-card" */).then(c => wrapFunctional(c.default || c))
export const FreeButtonFreeBookmarklet = () => import('../../src/components/free/ButtonFreeBookmarklet.vue' /* webpackChunkName: "components/free-button-free-bookmarklet" */).then(c => wrapFunctional(c.default || c))
export const FreeDiscoverMore = () => import('../../src/components/free/DiscoverMore.vue' /* webpackChunkName: "components/free-discover-more" */).then(c => wrapFunctional(c.default || c))
export const FreeCurationsButtons = () => import('../../src/components/free/FreeCurationsButtons.vue' /* webpackChunkName: "components/free-curations-buttons" */).then(c => wrapFunctional(c.default || c))
export const FreeEdmForm = () => import('../../src/components/free/FreeEdmForm.vue' /* webpackChunkName: "components/free-edm-form" */).then(c => wrapFunctional(c.default || c))
export const FreeExplore = () => import('../../src/components/free/FreeExplore.vue' /* webpackChunkName: "components/free-explore" */).then(c => wrapFunctional(c.default || c))
export const FreeHero = () => import('../../src/components/free/FreeHero.vue' /* webpackChunkName: "components/free-hero" */).then(c => wrapFunctional(c.default || c))
export const FreeHeroPartner = () => import('../../src/components/free/FreeHeroPartner.vue' /* webpackChunkName: "components/free-hero-partner" */).then(c => wrapFunctional(c.default || c))
export const FreeMediaTypesDropdown = () => import('../../src/components/free/FreeMediaTypesDropdown.vue' /* webpackChunkName: "components/free-media-types-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FreePartnerCurations = () => import('../../src/components/free/FreePartnerCurations.vue' /* webpackChunkName: "components/free-partner-curations" */).then(c => wrapFunctional(c.default || c))
export const FreeProducts = () => import('../../src/components/free/FreeProducts.vue' /* webpackChunkName: "components/free-products" */).then(c => wrapFunctional(c.default || c))
export const FreeSectionFreeButtons = () => import('../../src/components/free/SectionFreeButtons.vue' /* webpackChunkName: "components/free-section-free-buttons" */).then(c => wrapFunctional(c.default || c))
export const FreeStepsToDownload = () => import('../../src/components/free/StepsToDownload.vue' /* webpackChunkName: "components/free-steps-to-download" */).then(c => wrapFunctional(c.default || c))
export const FreeSubscribeFreeElements = () => import('../../src/components/free/SubscribeFreeElements.vue' /* webpackChunkName: "components/free-subscribe-free-elements" */).then(c => wrapFunctional(c.default || c))
export const HomePageHeroChannels = () => import('../../src/components/home-page/HeroChannels.vue' /* webpackChunkName: "components/home-page-hero-channels" */).then(c => wrapFunctional(c.default || c))
export const HomeBenefits = () => import('../../src/components/home-page/HomeBenefits.vue' /* webpackChunkName: "components/home-benefits" */).then(c => wrapFunctional(c.default || c))
export const HomeExplore = () => import('../../src/components/home-page/HomeExplore.vue' /* webpackChunkName: "components/home-explore" */).then(c => wrapFunctional(c.default || c))
export const HomeFaqs = () => import('../../src/components/home-page/HomeFaqs.vue' /* webpackChunkName: "components/home-faqs" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../src/components/home-page/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeLicensing = () => import('../../src/components/home-page/HomeLicensing.vue' /* webpackChunkName: "components/home-licensing" */).then(c => wrapFunctional(c.default || c))
export const HomePartners = () => import('../../src/components/home-page/HomePartners.vue' /* webpackChunkName: "components/home-partners" */).then(c => wrapFunctional(c.default || c))
export const HomeProducts = () => import('../../src/components/home-page/HomeProducts.vue' /* webpackChunkName: "components/home-products" */).then(c => wrapFunctional(c.default || c))
export const HomeSchemaServer = () => import('../../src/components/home-page/HomeSchema.server.vue' /* webpackChunkName: "components/home-schema-server" */).then(c => wrapFunctional(c.default || c))
export const HomeTestimonials = () => import('../../src/components/home-page/HomeTestimonials.vue' /* webpackChunkName: "components/home-testimonials" */).then(c => wrapFunctional(c.default || c))
export const HomePagePartnersList = () => import('../../src/components/home-page/PartnersList.vue' /* webpackChunkName: "components/home-page-partners-list" */).then(c => wrapFunctional(c.default || c))
export const HomePageSubscribeBanner = () => import('../../src/components/home-page/SubscribeBanner.vue' /* webpackChunkName: "components/home-page-subscribe-banner" */).then(c => wrapFunctional(c.default || c))
export const LegalAgreementContent = () => import('../../src/components/legal/AgreementContent.vue' /* webpackChunkName: "components/legal-agreement-content" */).then(c => wrapFunctional(c.default || c))
export const LegalDocument = () => import('../../src/components/legal/LegalDocument.vue' /* webpackChunkName: "components/legal-document" */).then(c => wrapFunctional(c.default || c))
export const LegalSidebar = () => import('../../src/components/legal/LegalSidebar.vue' /* webpackChunkName: "components/legal-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LegalMapId = () => import('../../src/components/legal/mapId.js' /* webpackChunkName: "components/legal-map-id" */).then(c => wrapFunctional(c.default || c))
export const PricingComparisonTable = () => import('../../src/components/pricing/ComparisonTable.vue' /* webpackChunkName: "components/pricing-comparison-table" */).then(c => wrapFunctional(c.default || c))
export const PricingUnlimitedSubscriptionPrice = () => import('../../src/components/pricing/UnlimitedSubscriptionPrice.vue' /* webpackChunkName: "components/pricing-unlimited-subscription-price" */).then(c => wrapFunctional(c.default || c))
export const ReferralCustomizeInvitation = () => import('../../src/components/referral/CustomizeInvitation.vue' /* webpackChunkName: "components/referral-customize-invitation" */).then(c => wrapFunctional(c.default || c))
export const ReferralBanner = () => import('../../src/components/referral/ReferralBanner.vue' /* webpackChunkName: "components/referral-banner" */).then(c => wrapFunctional(c.default || c))
export const ReferralBenefits = () => import('../../src/components/referral/ReferralBenefits.vue' /* webpackChunkName: "components/referral-benefits" */).then(c => wrapFunctional(c.default || c))
export const ReferralCodeBlock = () => import('../../src/components/referral/ReferralCodeBlock.vue' /* webpackChunkName: "components/referral-code-block" */).then(c => wrapFunctional(c.default || c))
export const ReferralHero = () => import('../../src/components/referral/ReferralHero.vue' /* webpackChunkName: "components/referral-hero" */).then(c => wrapFunctional(c.default || c))
export const ReferralStartEarningBtn = () => import('../../src/components/referral/ReferralStartEarningBtn.vue' /* webpackChunkName: "components/referral-start-earning-btn" */).then(c => wrapFunctional(c.default || c))
export const ReferralSteps = () => import('../../src/components/referral/ReferralSteps.vue' /* webpackChunkName: "components/referral-steps" */).then(c => wrapFunctional(c.default || c))
export const ReferralTabs = () => import('../../src/components/referral/ReferralTabs.vue' /* webpackChunkName: "components/referral-tabs" */).then(c => wrapFunctional(c.default || c))
export const ReferralTextLink = () => import('../../src/components/referral/ReferralTextLink.vue' /* webpackChunkName: "components/referral-text-link" */).then(c => wrapFunctional(c.default || c))
export const SearchLandingPage = () => import('../../src/components/search-lp/SearchLandingPage.vue' /* webpackChunkName: "components/search-landing-page" */).then(c => wrapFunctional(c.default || c))
export const SubmissionDiscoverMore = () => import('../../src/components/submission/DiscoverMore.vue' /* webpackChunkName: "components/submission-discover-more" */).then(c => wrapFunctional(c.default || c))
export const SubmissionLandingHero = () => import('../../src/components/submission/LandingHero.vue' /* webpackChunkName: "components/submission-landing-hero" */).then(c => wrapFunctional(c.default || c))
export const SubmissionSpecificationsSection = () => import('../../src/components/submission/SpecificationsSection.vue' /* webpackChunkName: "components/submission-specifications-section" */).then(c => wrapFunctional(c.default || c))
export const SubmissionCategories = () => import('../../src/components/submission/SubmissionCategories.vue' /* webpackChunkName: "components/submission-categories" */).then(c => wrapFunctional(c.default || c))
export const SubmissionHero = () => import('../../src/components/submission/SubmissionHero.vue' /* webpackChunkName: "components/submission-hero" */).then(c => wrapFunctional(c.default || c))
export const SubmissionSteps = () => import('../../src/components/submission/SubmissionSteps.vue' /* webpackChunkName: "components/submission-steps" */).then(c => wrapFunctional(c.default || c))
export const SubscribeFussFreeLicensing = () => import('../../src/components/subscribe/FussFreeLicensing.vue' /* webpackChunkName: "components/subscribe-fuss-free-licensing" */).then(c => wrapFunctional(c.default || c))
export const SubscribeBenefits = () => import('../../src/components/subscribe/SubscribeBenefits.vue' /* webpackChunkName: "components/subscribe-benefits" */).then(c => wrapFunctional(c.default || c))
export const SubscribeCatalog = () => import('../../src/components/subscribe/SubscribeCatalog.vue' /* webpackChunkName: "components/subscribe-catalog" */).then(c => wrapFunctional(c.default || c))
export const SubscribeCurations = () => import('../../src/components/subscribe/SubscribeCurations.vue' /* webpackChunkName: "components/subscribe-curations" */).then(c => wrapFunctional(c.default || c))
export const SubscribeHero = () => import('../../src/components/subscribe/SubscribeHero.vue' /* webpackChunkName: "components/subscribe-hero" */).then(c => wrapFunctional(c.default || c))
export const SubscribeHeroBanner = () => import('../../src/components/subscribe/SubscribeHeroBanner.vue' /* webpackChunkName: "components/subscribe-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const SubscribePaymentMethods = () => import('../../src/components/subscribe/SubscribePaymentMethods.vue' /* webpackChunkName: "components/subscribe-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const SubscribeScrollToTop = () => import('../../src/components/subscribe/SubscribeScrollToTop.vue' /* webpackChunkName: "components/subscribe-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const SubscribeStats = () => import('../../src/components/subscribe/SubscribeStats.vue' /* webpackChunkName: "components/subscribe-stats" */).then(c => wrapFunctional(c.default || c))
export const SubscribeTestimonials = () => import('../../src/components/subscribe/SubscribeTestimonials.vue' /* webpackChunkName: "components/subscribe-testimonials" */).then(c => wrapFunctional(c.default || c))
export const SubscribeTestimonialsItem = () => import('../../src/components/subscribe/SubscribeTestimonialsItem.vue' /* webpackChunkName: "components/subscribe-testimonials-item" */).then(c => wrapFunctional(c.default || c))
export const SubscribeTestimonialsItemKo = () => import('../../src/components/subscribe/SubscribeTestimonialsItemKo.vue' /* webpackChunkName: "components/subscribe-testimonials-item-ko" */).then(c => wrapFunctional(c.default || c))
export const SubscribeWebinar = () => import('../../src/components/subscribe/SubscribeWebinar.vue' /* webpackChunkName: "components/subscribe-webinar" */).then(c => wrapFunctional(c.default || c))
export const SubscribeTeamPlanBanner = () => import('../../src/components/subscribe/TeamPlanBanner.vue' /* webpackChunkName: "components/subscribe-team-plan-banner" */).then(c => wrapFunctional(c.default || c))
export const SubscribePromoBenefits = () => import('../../src/components/subscribe-promo/SubscribePromoBenefits.vue' /* webpackChunkName: "components/subscribe-promo-benefits" */).then(c => wrapFunctional(c.default || c))
export const SubscribePromoButton = () => import('../../src/components/subscribe-promo/SubscribePromoButton.vue' /* webpackChunkName: "components/subscribe-promo-button" */).then(c => wrapFunctional(c.default || c))
export const SubscribePromoHero = () => import('../../src/components/subscribe-promo/SubscribePromoHero.vue' /* webpackChunkName: "components/subscribe-promo-hero" */).then(c => wrapFunctional(c.default || c))
export const SubscribePromoReady = () => import('../../src/components/subscribe-promo/SubscribePromoReady.vue' /* webpackChunkName: "components/subscribe-promo-ready" */).then(c => wrapFunctional(c.default || c))
export const SubscribePromoSteps = () => import('../../src/components/subscribe-promo/SubscribePromoSteps.vue' /* webpackChunkName: "components/subscribe-promo-steps" */).then(c => wrapFunctional(c.default || c))
export const UserGuidePurchasingSection = () => import('../../src/components/user-guide/PurchasingSection.vue' /* webpackChunkName: "components/user-guide-purchasing-section" */).then(c => wrapFunctional(c.default || c))
export const UserGuideSavingContent = () => import('../../src/components/user-guide/SavingContent.vue' /* webpackChunkName: "components/user-guide-saving-content" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsCardSection = () => import('../../src/components/core/elements/CardSection.vue' /* webpackChunkName: "components/core-elements-card-section" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsColumnSection = () => import('../../src/components/core/elements/ColumnSection.vue' /* webpackChunkName: "components/core-elements-column-section" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsCustomSidebar = () => import('../../src/components/core/elements/CustomSidebar.vue' /* webpackChunkName: "components/core-elements-custom-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsImgWithDarkMode = () => import('../../src/components/core/elements/ImgWithDarkMode.vue' /* webpackChunkName: "components/core-elements-img-with-dark-mode" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsPageTabs = () => import('../../src/components/core/elements/PageTabs.vue' /* webpackChunkName: "components/core-elements-page-tabs" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsProductsGrid = () => import('../../src/components/core/elements/ProductsGrid.vue' /* webpackChunkName: "components/core-elements-products-grid" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsStepItem = () => import('../../src/components/core/elements/StepItem.vue' /* webpackChunkName: "components/core-elements-step-item" */).then(c => wrapFunctional(c.default || c))
export const CoreElementsStepItemHorizontal = () => import('../../src/components/core/elements/StepItemHorizontal.vue' /* webpackChunkName: "components/core-elements-step-item-horizontal" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseFlashNotifications = () => import('../../src/components/core/notifications/BaseFlashNotifications.vue' /* webpackChunkName: "components/core-notifications-base-flash-notifications" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseToastNotificationItem = () => import('../../src/components/core/notifications/BaseToastNotificationItem.vue' /* webpackChunkName: "components/core-notifications-base-toast-notification-item" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseToastNotifications = () => import('../../src/components/core/notifications/BaseToastNotifications.vue' /* webpackChunkName: "components/core-notifications-base-toast-notifications" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchHeroSearchBar = () => import('../../src/components/core/search/HeroSearchBar.vue' /* webpackChunkName: "components/core-search-hero-search-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchBar = () => import('../../src/components/core/search/SearchBar.vue' /* webpackChunkName: "components/core-search-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchBarTypeaheadList = () => import('../../src/components/core/search/SearchBarTypeaheadList.vue' /* webpackChunkName: "components/core-search-bar-typeahead-list" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchBarTypeaheadListButton = () => import('../../src/components/core/search/SearchBarTypeaheadListButton.vue' /* webpackChunkName: "components/core-search-bar-typeahead-list-button" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupCheckList = () => import('../../src/components/contributor/signup/CheckList.vue' /* webpackChunkName: "components/contributor-signup-check-list" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignUpLink = () => import('../../src/components/contributor/signup/ContributorSignUpLink.vue' /* webpackChunkName: "components/contributor-sign-up-link" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupEarningsSteps = () => import('../../src/components/contributor/signup/EarningsSteps.vue' /* webpackChunkName: "components/contributor-signup-earnings-steps" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupHero = () => import('../../src/components/contributor/signup/Hero.vue' /* webpackChunkName: "components/contributor-signup-hero" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupHeroFooter = () => import('../../src/components/contributor/signup/HeroFooter.vue' /* webpackChunkName: "components/contributor-signup-hero-footer" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupLookingFor = () => import('../../src/components/contributor/signup/LookingFor.vue' /* webpackChunkName: "components/contributor-signup-looking-for" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupPerks = () => import('../../src/components/contributor/signup/Perks.vue' /* webpackChunkName: "components/contributor-signup-perks" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupReferrals = () => import('../../src/components/contributor/signup/Referrals.vue' /* webpackChunkName: "components/contributor-signup-referrals" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupSales = () => import('../../src/components/contributor/signup/Sales.vue' /* webpackChunkName: "components/contributor-signup-sales" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupWaysToSell = () => import('../../src/components/contributor/signup/WaysToSell.vue' /* webpackChunkName: "components/contributor-signup-ways-to-sell" */).then(c => wrapFunctional(c.default || c))
export const FreeModalsInvite = () => import('../../src/components/free/modals/Invite.vue' /* webpackChunkName: "components/free-modals-invite" */).then(c => wrapFunctional(c.default || c))
export const ModulesCommonVStyle = () => import('../../src/components/modules/common/VStyle.vue' /* webpackChunkName: "components/modules-common-v-style" */).then(c => wrapFunctional(c.default || c))
export const SubscribeBtn = () => import('../../src/components/subscribe/price/SubscribeBtn.vue' /* webpackChunkName: "components/subscribe-btn" */).then(c => wrapFunctional(c.default || c))
export const SubscribePrice = () => import('../../src/components/subscribe/price/SubscribePrice.vue' /* webpackChunkName: "components/subscribe-price" */).then(c => wrapFunctional(c.default || c))
export const SubscribePriceReseller = () => import('../../src/components/subscribe/price/SubscribePriceReseller.vue' /* webpackChunkName: "components/subscribe-price-reseller" */).then(c => wrapFunctional(c.default || c))
export const SubscribePricesCards = () => import('../../src/components/subscribe/price/SubscribePricesCards.vue' /* webpackChunkName: "components/subscribe-prices-cards" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupFaq = () => import('../../src/components/contributor/signup/faq/Faq.vue' /* webpackChunkName: "components/contributor-signup-faq" */).then(c => wrapFunctional(c.default || c))
export const ContributorSignupFaqSearch = () => import('../../src/components/contributor/signup/faq/Search.vue' /* webpackChunkName: "components/contributor-signup-faq-search" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
